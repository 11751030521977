import { map, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs/Rx';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
    constructor(
        private _router: Router,
        private _autenticacaoService: AuthService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> | boolean {
        return this._autenticado();
    }

    canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
        return this._autenticado();
    }

    private _autenticado(): any {
        // console.log("******************* AUTENTICACAO GUARD *******************")

        this._autenticacaoService.recuperarLogin(false);

        return this._autenticacaoService.autenticado$.pipe(
            first(),
            map((r) => {
                // console.log("USUARIO AUTENTICADO = ", r)
                if (r) {
                    return true;
                }

                this._router.navigate(['/login']);
                return false;
            })
        );
    }
}
