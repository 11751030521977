import { Component, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-message-dialog',
    template: `
        <div class="message-dialog" fxLayout="column">
            <span mat-dialog-title class="title">
                {{ title }}
            </span>

            <mat-dialog-content class="message" fxFlex>
                <span [innerHtml]="message"> </span>
            </mat-dialog-content>

            <mat-dialog-actions style="margin-bottom: 0px;">
                <div fxLayout="row">
                    <button mat-button (click)="dialogRef.close(true)">
                        OK
                    </button>
                </div>
            </mat-dialog-actions>
        </div>
    `,
    styles: [
        `
            .message-dialog {
                height: 100%;
                min-height: 220px;
            }
            .title {
                padding-top: 20px;
                font-family: 'Open Sans', sans-serif;
                font-size: 18px;
                font-weight: 500;
            }
            .message {
                min-height: 65px;
                font-family: 'Open Sans', sans-serif;
                font-size: 14px;
            }
        `,
    ],
})
export class MessageDialogComponent implements OnInit {
    public title: string;
    public message: string;

    constructor(public dialogRef: MatDialogRef<MessageDialogComponent>) {}

    ngOnInit() {}
}
