import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedServicesModule } from '../shared-services/shared-services.module';
import { AuthModule } from './../auth/auth.module';

@NgModule({
    imports: [CommonModule, AuthModule, SharedServicesModule],
    exports: [],
    declarations: [],
    entryComponents: [],
})
export class CoreModule {}
