import { Injectable, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { Observable, Subject } from 'rxjs/Rx';

import { ConfirmDialogComponent } from './confirm-dialog.component';

@Injectable()
export class ConfirmService {
    constructor(private _dialog: MatDialog) {}

    message(
        title: string,
        message: string,
        viewContainerRef: ViewContainerRef
    ): Observable<boolean> {
        let dialogRef: MatDialogRef<ConfirmDialogComponent>;

        let config = new MatDialogConfig();
        config.viewContainerRef = viewContainerRef;
        config.disableClose = true;
        config.width = '450px';
        config.height = '225px';
        config.id = 'confirmdialog';

        dialogRef = this._dialog.open(ConfirmDialogComponent, config);

        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;

        let result = new Subject<boolean>();

        dialogRef.afterClosed().subscribe((r) => {
            dialogRef = null;
            return result.next(r);
        });
        return result;
    }
}
