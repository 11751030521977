import { HandleErrorModel } from '../../core/models/handle-error.model';
import { AppHelper } from './../../app.helper';
import { AuthAction, AuthActions } from './auth.actions';
import { authInitialState, AuthState } from './auth.state';

export function authReducer(
    state = authInitialState,
    action: AuthAction
): AuthState {
    switch (action.type) {
        case AuthActions.EFETUAR_LOGIN:
        case AuthActions.EFETUAR_LOGIN_GOOGLE:
        case AuthActions.EFETUAR_LOGIN_INTERNO: {
            return handleAuthEfetuarLoginAction(state, action);
        }

        case AuthActions.LOGIN_EFETUADO_COM_SUCESSO: {
            return handleAuthLoginEfetuadoComSucessoAction(state, action);
        }

        case AuthActions.LOGIN_NAO_EFETUADO: {
            return handleAuthLoginNaoEfetuadoAction(state, action);
        }

        case AuthActions.RECUPERAR_LOGIN: {
            return handleAuthRecuperarLoginAction(state, action);
        }

        case AuthActions.LOGIN_RECUPERADO_COM_SUCESSO: {
            return handleAuthLoginRecuperadoAction(state, action);
        }

        case AuthActions.EFETUAR_LOGOUT: {
            return handleAuthEfetuarLogoutAction(state, action);
        }

        case AuthActions.LOGOUT_EFETUADO: {
            return handleAuthLogoutEfetuadoAction(state, action);
        }

        case AuthActions.AUTENTICAR: {
            return handleAutenticarAction(state, action);
        }

        case AuthActions.AUTENTICADO_COM_SUCESSO: {
            return handleAutenticadoComSucessoAction(state, action);
        }

        case AuthActions.DADOS_FORNECEDOR_CARREGAR: {
            return handleDadosFornecedorCarregarAction(state, action);
        }

        case AuthActions.DADOS_FORNECEDOR_CARREGAR_COM_SUCESSO: {
            return handleDadosFornecedorCarregarComSucessoAction(state, action);
        }

        case AuthActions.CRIAR_ACESSO: {
            return handleCriarAcessoAction(state, action);
        }

        case AuthActions.CRIAR_ACESSO_COM_SUCESSO: {
            return handleCriarAcessoComSucessoAction(state, action);
        }

        case AuthActions.TROCAR_EMAIL: {
            return handleTrocarEmailAction(state, action);
        }

        case AuthActions.LOGIN_NAO_REGISTRADO: {
            return handleLoginNaoRegistradoAction(state, action);
        }

        case AuthActions.LOGOUT_NAO_REGISTRADO: {
            return handleLogoutNaoRegistradoAction(state, action);
        }

        case AuthActions.RESETAR_SENHA: {
            return handleAuthResetarSenhaAction(state, action);
        }

        case AuthActions.SENHA_RESETADA_COM_SUCESSO: {
            return handleAuthSenhaResetadaAction(state, action);
        }

        case AuthActions.REDEFINIR_SENHA: {
            return handleAuthRedefinirSenhaAction(state, action);
        }

        case AuthActions.SENHA_REDEFINIDA_COM_SUCESSO: {
            return handleAuthSenhaRedefinidaAction(state, action);
        }

        case AuthActions.LIMPAR: {
            return handleAuthLimparAction(state, action);
        }

        case AuthActions.ERRO: {
            return handleAuthErroAction(state, action);
        }

        default: {
            return state;
        }
    }
}

function handleAuthEfetuarLoginAction(
    state: AuthState,
    action: any
): AuthState {
    return {
        ...state,
        user: undefined,
        error: null,
        isProcessing: true,
        message: null,
        authenticated: false,
    };
}

function handleAuthLoginEfetuadoComSucessoAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("LOGIN EFETUADO", action.payload)

    return {
        ...state,
        user: action.payload,
        error: null,
        isProcessing: false,
        message: null,
        authenticated: action.payload ? true : false,
    };
}

function handleAuthLoginNaoEfetuadoAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("LOGIN NAO EFETUADO", action.payload)

    let e = new HandleErrorModel(action.payload);
    if (action.payload) {
        if (action.payload.code) {
            if (action.payload.code == 'auth/wrong-password') {
                e.errorMessage = 'E-mail ou Senha inválidos.';
            }

            if (action.payload.code == 'auth/user-not-found') {
                e.errorMessage = 'E-mail não cadastrado.';
            }
        }

        if (action.payload.status && action.payload.status == '401') {
            e.errorMessage = 'E-mail ou Senha inválidos.';
        }
    }

    return {
        ...state,
        user: null,
        error: null,
        isProcessing: false,
        message:
            e && e.errorMessage
                ? e.errorMessage
                : 'Não foi possível efetuar o login.',
        authenticated: false,
    };
}

function handleAuthRecuperarLoginAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("RECUPERAR LOGIN", action.payload)

    return {
        ...state,
        user: undefined,
        error: null,
        message: null,
        authenticated: false,
    };
}

function handleAuthLoginRecuperadoAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("LOGIN RECUPERADO", action.payload)

    return {
        ...state,
        user: action.payload,
        error: null,
        isProcessing: false,
        message: null,
        authenticated: action.payload ? true : false,
    };
}

function handleAutenticarAction(state: AuthState, action: any): AuthState {
    // console.log("AUTENTICAR", action.payload)

    return {
        ...state,
        error: null,
        message: null,
    };
}

function handleAutenticadoComSucessoAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("AUTENTICADO COM SUCESSO", action.payload)

    return {
        ...state,
        error: null,
        isProcessing: false,
        message: null,
    };
}

function handleDadosFornecedorCarregarAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("DADOS FORNECEDOR CARREGAR", action.payload)

    return {
        ...state,
        error: null,
        message: null,
        cnpjCpfFornecedor: null,
    };
}

function handleDadosFornecedorCarregarComSucessoAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("DADOS FORNECEDOR CARREGADO", action.payload);

    let _user = Object.assign({}, state.user);
    if (action.payload && action.payload.sucesso) {
        _user.numeroDoFornecedor = action.payload.numeroDoFornecedor;
        _user.nomeFornecedor = action.payload.nomeFornecedor;
        _user.natureza = action.payload.natureza;
        _user.cnpjcpf =
            action.payload.natureza == 'F'
                ? action.payload.cpf
                : action.payload.cnpj;
    }

    return {
        ...state,
        user: _user,
        error: null,
        message: null,
        cnpjCpfFornecedor:
            _user && _user.cnpjcpf
                ? _user.cnpjcpf
                : AppHelper.FORNECEDOR_CNPJ_CPJ_NAO_INFORMADO,
    };
}

function handleCriarAcessoAction(state: AuthState, action: any): AuthState {
    // console.log("CRIAR ACESSO", action.payload);

    return {
        ...state,
        user: null,
        error: null,
        isProcessing: true,
        message: null,
        authenticated: false,
    };
}

function handleCriarAcessoComSucessoAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("CRIAR ACESSO COM SUCESSO", action.payload);

    return {
        ...state,
        isProcessing: false,
        message: `Seu acesso foi criado com sucesso.`,
    };
}

function handleTrocarEmailAction(state: AuthState, action: any): AuthState {
    // console.log("TROCAR EMAIL", action.payload)

    let usr = state.user;
    if (state.user.loginInterno) {
        usr = Object.assign({}, state.user);
        usr.email = action.payload;
        usr.nome = action.payload;
        usr.nomeDoColaborador = action.payload;
    }

    return {
        ...state,
        user: usr,
        error: null,
        isProcessing: false,
        message: null,
    };
}

function handleAuthEfetuarLogoutAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("EFETUAR LOGOUT", action.payload);

    return {
        ...state,
        user: null,
        error: null,
        isProcessing: false,
        message: null,
        authenticated: false,
    };
}

function handleAuthLogoutEfetuadoAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("LOGOUT EFETUADO", action.payload);

    return {
        ...state,
        user: null,
        error: null,
        isProcessing: false,
        message: null,
        authenticated: false,
    };
}

function handleLoginNaoRegistradoAction(
    state: AuthState,
    action: any
): AuthState {
    console.log('*** NAO FOI POSSIVEL REGISTRAR O LOGIN ***');
    return state;
}

function handleLogoutNaoRegistradoAction(
    state: AuthState,
    action: any
): AuthState {
    console.log('*** NAO FOI POSSIVEL REGISTRAR O LOGOUT ***');
    return state;
}

function handleAuthResetarSenhaAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("RESETAR SENHA")

    return {
        ...state,
        user: null,
        error: null,
        isProcessing: true,
        message: null,
        authenticated: false,
    };
}

function handleAuthSenhaResetadaAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("SENHA RESETADA")

    return {
        ...state,
        user: null,
        error: null,
        isProcessing: false,
        message: 'Foi enviado um e-mail para redefinição de senha.',
        authenticated: false,
    };
}

function handleAuthRedefinirSenhaAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("REDEFINIR SENHA", action.payload)

    return {
        ...state,
        user: null,
        error: null,
        isProcessing: true,
        message: null,
        authenticated: false,
    };
}

function handleAuthSenhaRedefinidaAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("SENHA REDEFINIDA", action.payload)

    return {
        ...state,
        user: null,
        error: null,
        isProcessing: false,
        message: 'Senha alterada com sucesso.',
        authenticated: false,
    };
}

function handleAuthLimparAction(state: AuthState, action: any): AuthState {
    // console.log("LIMPAR", action.payload)

    return {
        ...state,
        user: null,
        error: null,
        isProcessing: false,
        message: null,
        authenticated: false,
    };
}

function handleAuthErroAction(state: AuthState, action: any): AuthState {
    // console.log("ERRO REDUCER =", action.payload);

    return {
        ...state,
        user: null,
        error: action.payload,
        isProcessing: false,
        message: null,
        authenticated: false,
    };
}
