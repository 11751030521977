import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AppService {
    public applicationProcessing: Subject<boolean> = new Subject<boolean>();
    public isMobile: Subject<boolean> = new Subject<boolean>();

    constructor() {}

    setIsProcessing() {
        this.applicationProcessing.next(true);
    }

    setFinishedProcessing() {
        this.applicationProcessing.next(false);
    }

    setIsMobile(b) {
        this.isMobile.next(b);
    }
}
