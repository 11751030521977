import { Action, Store } from '@ngrx/store';

import { AppState } from './app.reducer';

export abstract class StoreService {
    protected readonly STATE;
    protected store: Store<AppState>;

    protected storeSelectFeatureState() {
        return this.store.select(this.STATE);
    }

    protected dispatchAction(action: Action) {
        this.store.dispatch(action);
    }

    // abstract dispatchLoadRecordsAction();
    // abstract dispatchSearchRecordsAction(term: any);
    // abstract dispatchClearSearchAction();
    // abstract dispatchNewRecordAction(record: any);
    // abstract dispatchEditRecordAction(record: any);
    // abstract dispatchCreateAction(record: any);
    // abstract dispatchUpdateAction(record: any);
    // abstract dispatchRemoveAction(record: any);
}
