import { MessageService } from './../../shared-services/message/message.service';
export class HandleErrorModel {
    status: string;
    statusText: string;
    errorMessage: string;

    constructor(exception: any) {
        this.status = '';
        this.statusText = '';
        this.errorMessage = 'Ocorreu um erro...';

        // console.log("HANDLER EXCEPTION=", exception);

        // FIREBASE ERROR
        if (exception && (exception.code || exception.errorMessage)) {
            if (exception.code == 'auth/invalid-action-code') {
                this.errorMessage =
                    'Não foi possível redefinir uma nova senha.';
                return;
            }

            if (exception.code == 'auth/user-not-found') {
                this.errorMessage =
                    'O e-mail informado não está cadastrado. Caso você seja um novo usuário e queira se cadastrar, volte para a tela de entrada e clique na opção "Crie uma"';
                return;
            }

            if (exception.code == 'auth/too-many-requests') {
                this.errorMessage =
                    'Ocorreu um erro interno no sistema. Por favor, tente executar a operação novamente e se o erro persistir, entre em contato com nosso suporte técnico.';
                return;
            }

            if (exception.errorMessage) {
                this.errorMessage = exception.errorMessage;
                return;
            }
        }

        if (!exception || !exception.status || !exception.statusText) {
            if (
                exception &&
                exception.message &&
                exception.message == 'Timeout has occurred'
            ) {
                console.log('Timeout - Esgotou o tempo máximo da requisição.');
            }
            return;
        }

        let e = null;
        if (exception.status && exception.statusText) {
            if (exception.error) {
                if (exception.error.errors) {
                    // Notificações Backend
                    e = exception.error;
                } else {
                    if (
                        exception.error.message &&
                        exception.error.exceptionMessage
                    ) {
                        // C# Error Backend
                    } else {
                        // e = JSON.parse(exception.error);
                    }
                }
            }
        } else {
            e = exception.json();
        }

        if (e && e.error && e.error_description) {
            // Invalid User (Authentication)
            if (e.error == 'invalid_grant') {
                this.errorMessage = 'Não foi possível validar e-mail/senha.';
                return;
            }

            // Authentication error
            if (e.error == 'invalid_authentication') {
                this.errorMessage = 'Não foi possível validar e-mail/senha.';
                // this.errorMessage = e.error_description.replace(/\- /g, '');
                return;
            }
        }

        this.status = exception.status;
        this.statusText = exception.statusText;
        this.errorMessage = e && e.message ? e.message : '';

        // Tratar HttpStatus
        if (this.status) {
            // 401 - NOT FOUND
            if (this.status == '401') {
                this.errorMessage =
                    'Você não tem permissão para executar essa operação.';
            }

            // 404 - NOT FOUND
            if (this.status == '404') {
                this.errorMessage = 'Registro não localizado.';
            }
        }

        // Tratar mensagens de erros/notificações da aplicação. Retornar a primeira mensagem.
        let errorMessages: string[] = [];
        if (e && e.errors && e.errors.length > 0) {
            for (let i = 0; i < e.errors.length; i++) {
                errorMessages.push(e.errors[i].value);
            }

            if (errorMessages && errorMessages.length > 0) {
                this.status = exception.status;
                this.statusText = exception.statusText;
                this.errorMessage = errorMessages[0];
            }
        }
    }
}
