import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';

import { AppSettings } from './app.settings';

@Injectable()
export class AppTokenInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.url.startsWith('https://viacep.com')) {
            return next.handle(req);
        }

        if (req.url.endsWith('/auth/client-credentials')) {
            return next.handle(req);
        }

        if (req.url.endsWith('/auth/validate/otk')) {
            return next.handle(req);
        }

        req = req.clone({
            headers: AppSettings.HTTP_HEADERS_AUTHORIZATION,
        });

        return next.handle(req);
    }
}
