import { NgModule } from '@angular/core';

import { AuthGuard } from './guards/auth.guard';
import { CnpjObrigatorioGuard } from './guards/cnpj-obrigatorio.guard';
import { CnpjGuard } from './guards/cnpj.guard';
import { AuthService } from './services/auth.service';
import { AuthStoreModule } from './store/auth.store.module';

@NgModule({
    imports: [AuthStoreModule],
    providers: [AuthService, AuthGuard, CnpjGuard, CnpjObrigatorioGuard],
})
export class AuthModule {}
