import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { delay, filter, switchMap } from 'rxjs/operators';

import { AppSettings } from '../../app.settings';
import { FireAuthService } from '../../firebase/services/fire-auth.service';
import { AuthStoreService } from '../store/auth.store.service';
import { AppHelper } from './../../app.helper';
import { AuthUser } from './../models/auth-user.model';

@Injectable()
export class AuthService {
    autenticado: boolean = false;
    usuario: AuthUser;

    autenticado$: Observable<boolean>;
    usuario$: Observable<AuthUser>;
    usuarioInvalido$: Observable<AuthUser>;

    numeroDoFornecedor$: BehaviorSubject<number> = new BehaviorSubject<number>(
        null
    );
    email$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    cnpjcpf$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    cnpjcpfInformado$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );

    isProcessing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    error$: Observable<any>;
    message$: Observable<any>;
    invalidAuth$: Subject<any> = new Subject<any>();

    constructor(
        private _storeService: AuthStoreService,
        private _authfire: FireAuthService
    ) {
        this.usuario$ = this._storeService
            .getUser()
            .pipe(filter((r) => r != null && r != undefined));

        this.usuarioInvalido$ = this._storeService.getIsProcessing().pipe(
            filter((r) => r == false),
            delay(500),
            switchMap(() =>
                this._storeService.getUser().pipe(filter((r) => !r || !r.email))
            )
        );

        this.error$ = this._storeService.getError();
        this.message$ = this._storeService.getMessage();
        this.autenticado$ = this._storeService.getAuthenticated();

        this._storeService
            .getCnpjCpfFornecedor()
            .subscribe((r) =>
                this.cnpjcpfInformado$.next(
                    r == AppHelper.FORNECEDOR_CNPJ_CPJ_NAO_INFORMADO
                        ? false
                        : true
                )
            );

        this._storeService
            .getIsProcessing()
            .subscribe((r) => this.isProcessing$.next(r));

        AppSettings.token$.subscribe((r) => {
            if (!r) {
                this.invalidAuth$.next();
            }
        });

        this.autenticado$.subscribe((r) => {
            this.autenticado = r;
        });

        this.usuario$.subscribe((r) => {
            this.usuario = r;
            // console.log('USUARIO ->', r);
            this.email$.next(this.usuario ? this.usuario.email : null);
            this.cnpjcpf$.next(this.usuario ? this.usuario.cnpjcpf : null);
            this.numeroDoFornecedor$.next(
                this.usuario ? this.usuario.numeroDoFornecedor : null
            );
        });
    }

    limpar(): void {
        this._storeService.dispatchLimpar();
    }

    trocarEmail(email: string): void {
        this._storeService.dispatchTrocarEmail(email);
    }

    efetuarLogin(email: string, senha: string) {
        if (email.toLowerCase().endsWith('@otk.com.br')) {
            this._storeService.dispatchEfetuarLoginInterno(email, senha);
            return;
        }

        this._storeService.dispatchEfetuarLogin(email, senha);
    }

    verificarLoginExisteFirebase(email: string): Observable<any> {
        return from(
            this._authfire
                .signIn(email, '@#^Ç~AVOIDPASSWORD')
                .then(() => {
                    return true;
                })
                .catch((error) => {
                    // console.log("VERIFY LOGIN ERROR", error);
                    if (error.code == 'auth/user-not-found') {
                        return false; // NOT FOUND
                    }
                    if (error.code == 'auth/wrong-password') {
                        return true; // FOUND
                    }
                    return false;
                })
        );
    }

    efetuarGoogleLogin() {
        this._storeService.dispatchEfetuarLoginGoogle();
    }

    recuperarLogin(force: boolean = false): void {
        if (!force) {
            if (this.autenticado) {
                return;
            }
        }

        this._storeService.dispatchRecuperarLogin();
    }

    criarConta(email: string, senha: string): void {
        this._storeService.dispatchCriarAcesso(email, senha);
    }

    efetuarLogout(): void {
        this._storeService.dispatchRegistrarLogout();
        this._storeService.dispatchEfetuarLogout();
    }

    resetarSenha(email: string): void {
        this._storeService.dispatchResetarSenha(email);
    }

    redefinirSenha(code, senha): void {
        this._storeService.dispatchRedefinirSenha(code, senha);
    }

    setIsProcessing(b: boolean = true): void {
        this.isProcessing$.next(b);
    }

    recarregarDadosDoFornecedor(): void {
        if (!this.email$.value) {
            return;
        }
        this._storeService.dispatchCarregarDadosFornecedor(this.email$.value);
    }

    // habilitarNotificacoes(): void {
    //     this.authApiService.habilitarFireMessaging();
    // }

    // messagingServiceWorker$(): any {
    //     return this.authApiService.messaging$;
    // }
}
