import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';

import { StoreService } from '../../store/app-store.service';
import { AppState } from '../../store/app.reducer';
import * as act from './auth.actions';
import { AuthState, AUTH_STORE } from './auth.state';

@Injectable()
export class AuthStoreService extends StoreService {
    protected readonly STATE = AUTH_STORE;

    constructor(protected store: Store<AppState>) {
        super();
    }

    dispatchEfetuarLogin(email: string, senha: string) {
        this.dispatchAction(new act.EfetuarLoginAction({ email, senha }));
    }

    dispatchEfetuarLoginGoogle() {
        this.dispatchAction(new act.EfetuarLoginGoogleAction());
    }

    dispatchEfetuarLoginInterno(email: string, senha: string) {
        this.dispatchAction(
            new act.EfetuarLoginInternoAction({ email, senha })
        );
    }

    dispatchRecuperarLogin() {
        this.dispatchAction(new act.RecuperarLoginAction());
    }

    dispatchAutenticar(email: string) {
        this.dispatchAction(new act.AutenticarAction(email));
    }

    dispatchCarregarDadosFornecedor(email: string) {
        this.dispatchAction(new act.DadosFornecedorCarregarAction(email));
    }

    dispatchCriarAcesso(email: string, senha: string) {
        this.dispatchAction(new act.CriarAcessoAction({ email, senha }));
    }

    dispatchTrocarEmail(email: string) {
        this.dispatchAction(new act.TrocarEmailAction(email));
    }

    dispatchResetarSenha(email: string) {
        this.dispatchAction(new act.ResetarSenhaAction(email));
    }

    dispatchRedefinirSenha(code, senha) {
        this.dispatchAction(new act.RedefinirSenhaAction({ code, senha }));
    }

    dispatchEfetuarLogout() {
        this.dispatchAction(new act.EfetuarLogoutAction());
    }

    dispatchRegistrarLogout() {
        this.dispatchAction(new act.RegistrarLogoutAction());
    }

    dispatchLimpar() {
        this.dispatchAction(new act.LimparAction());
    }

    getUser() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user)
        );
    }

    getIsProcessing() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.isProcessing),
            filter((r) => r == true || r == false)
        );
    }

    getError() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.error),
            filter((r) => r)
        );
    }

    getMessage() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.message),
            filter((r) => r)
        );
    }

    getAuthenticated() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state),
            filter((state: AuthState) => state.isProcessing == false),
            map((state: AuthState) => state.authenticated)
        );
    }

    getCnpjCpfFornecedor() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.cnpjCpfFornecedor),
            filter((r) => r != null && r != undefined)
        );
    }
}
