import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatSnackBarModule,
} from '@angular/material';

import { ConfirmDialogComponent } from './confirm/confirm-dialog.component';
import { ConfirmService } from './confirm/confirm.service';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { MessageDialogService } from './message-dialog/message-dialog.service';
import { MessageService } from './message/message.service';
import { SnackBarMessageComponent } from './snack-message/snack-bar-message.component';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatSnackBarModule,
    ],
    exports: [],
    declarations: [
        ConfirmDialogComponent,
        MessageDialogComponent,
        SnackBarMessageComponent,
    ],
    entryComponents: [
        MessageDialogComponent,
        ConfirmDialogComponent,
        SnackBarMessageComponent,
    ],
    providers: [ConfirmService, MessageDialogService, MessageService],
})
export class SharedServicesModule {}
