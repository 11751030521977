import { Injectable, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs/Rx';

import { MessageDialogComponent } from './message-dialog.component';

@Injectable()
export class MessageDialogService {
    constructor(private _dialog: MatDialog) {}

    message(
        title: string,
        message: string,
        viewContainerRef: ViewContainerRef,
        height: string = null
    ): Observable<boolean> {
        let dialogRef: MatDialogRef<MessageDialogComponent>;

        let _config = new MatDialogConfig();
        _config.viewContainerRef = viewContainerRef;
        _config.disableClose = true;
        _config.height = '210px';
        if (height) {
            _config.height = height;
        }

        dialogRef = this._dialog.open(MessageDialogComponent, _config);

        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;

        return dialogRef.afterClosed();
    }

    errorMessageFmt(
        e: any,
        viewContainerRef: ViewContainerRef
    ): Observable<boolean> {
        let message = 'Ocorreu um erro...';
        if (e) {
            if (typeof e === 'string') {
                message = e;
            }

            if (e.errorMessage) {
                message = e.errorMessage;
            }
        }

        let dialogRef: MatDialogRef<MessageDialogComponent>;
        let _config = new MatDialogConfig();
        _config.viewContainerRef = viewContainerRef;

        dialogRef = this._dialog.open(MessageDialogComponent, _config);

        dialogRef.componentInstance.title = 'Atenção';
        dialogRef.componentInstance.message = message;

        return dialogRef.afterClosed();
    }
}
