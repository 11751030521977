import { FormControl } from '@angular/forms';
import * as saveAs from 'file-saver';
import * as moment from 'moment';

import { StateModeEnum } from './app.enums';
import { BehaviorSubject } from 'rxjs';

export class AppHelper {
    private static sessionGuid$: BehaviorSubject<string> =
        new BehaviorSubject<string>(AppHelper.createGuid());

    public static readonly FORNECEDOR_CNPJ_CPJ_NAO_INFORMADO = 'Não informado.';

    public static strToDate(value: any) {
        if (!value) return value;

        if (
            (typeof value === 'string' || value instanceof String) &&
            value.length > 9
        ) {
            let yy = +value.slice(0, 4);
            let mm = +value.slice(5, 7) - 1;
            let dd = +value.slice(8, 10);
            return new Date(yy, mm, dd);
        }
        return value;
    }

    public static strToDateTime(value: any) {
        if (!value) return value;

        if (
            (typeof value === 'string' || value instanceof String) &&
            value.length > 9
        ) {
            let yy = +value.slice(0, 4);
            let mm = +value.slice(5, 7) - 1;
            let dd = +value.slice(8, 10);

            let hh = +value.slice(11, 13);
            let MM = +value.slice(14, 16);
            let ss = +value.slice(17, 19);
            return new Date(yy, mm, dd, hh, MM, ss);
        }
        return value;
    }

    public static incDayFrowNow(i: number = 1) {
        let dt = new Date();
        let d = moment(dt);
        return d.add(i, 'd').toDate();
    }
    public static getMonth(value?: Date): number {
        if (!value) {
            return 0;
        }
        let r = value.getMonth() + 1;
        return r;
    }

    public static currentDate(): Date {
        return new Date();
    }
    public static currentYear(): number {
        let d = new Date();
        return d.getFullYear();
    }
    public static currentMonth(): number {
        let d = new Date();
        let r = d.getMonth() + 1;
        return r;
    }
    public static getDaysBetweenDates(startDate: Date, endDate: Date): number {
        const diff = endDate.getTime() - startDate.getTime();
        const r = diff / (1000 * 3600 * 24);
        return Math.floor(r) + 1;
        // return Math.ceil(r) + 1;
        // return this.round(r, 0);
    }

    public static round(value: number, dec: number = 2) {
        let r = Number(value).toFixed(dec);
        return Number(r);
    }

    public static setCtrlEnable(ctrl: FormControl, b: boolean = true): void {
        if (!ctrl) {
            return;
        }

        if (b) {
            if (ctrl.disabled) {
                ctrl.enable();
            }
        } else {
            if (ctrl.enabled) {
                ctrl.disable();
            }
        }
    }

    public static setCtrlValue(
        ctrl: FormControl,
        value: any,
        markastouched: boolean = false
    ): void {
        if (!ctrl) {
            return;
        }

        let r = ctrl.value;
        if (r != value) {
            ctrl.setValue(value);

            if (markastouched) {
                ctrl.markAsTouched();
            }
        }
    }
    public static setCtrlMarkAsTouched(ctrl: FormControl): void {
        if (!ctrl) {
            return;
        }

        ctrl.markAsTouched();
    }
    public static setCtrlMarkAsUntouched(ctrl: FormControl): void {
        if (!ctrl) {
            return;
        }

        ctrl.markAsUntouched();
    }

    public static validateEmail(email: any): boolean {
        var re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    private static _fileNameXMLHttpRequest(res: any): string {
        let f = 'DOWNLOAD';

        if (!res) {
            return f;
        }

        let contentDisposition = res.getResponseHeader('Content-Disposition');
        if (!contentDisposition) {
            return f;
        }

        let r = contentDisposition
            .split(';')[1]
            .trim()
            .split('=')[1]
            .replace(/"/g, '');
        if (r && r.length > 1) {
            f = r;
        }

        return f;
    }
    public static saveFileXMLHttpRequest(res: XMLHttpRequest): void {
        let f = this._fileNameXMLHttpRequest(res);
        let b = res.response;
        saveAs.saveAs(b, f);
    }

    private static _fileNameResponse(res: Response): string {
        let f = 'DOWNLOAD';

        if (!res || !res.headers) {
            return f;
        }

        let contentDisposition = res.headers.get('Content-Disposition');
        if (!contentDisposition) {
            return f;
        }

        let r = contentDisposition
            .split(';')[1]
            .trim()
            .split('=')[1]
            .replace(/"/g, '');
        if (r && r.length > 1) {
            f = r;
        }

        return f;
    }
    public static saveFileResponse(res: Response | any): void {
        let f = this._fileNameResponse(res);
        saveAs.saveAs(res.body, f);
    }

    public static isURL(value: any): boolean {
        var pattern =
            /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return pattern.test(value);
    }

    public static numberToFmtStr(value: number): string {
        if (!value) return '0,00';

        let r = Number(value).toFixed(2);

        r = r.replace(/\B(?=(\d{3})+(?!\d))/g, '@');
        r = r.replace(/\./g, ',');
        r = r.replace(/\@/g, '.');
        return r;
    }

    public static isInsertEditMode(state: StateModeEnum): boolean {
        return state == StateModeEnum.insert || state == StateModeEnum.edit;
    }
    public static isInsertMode(state: StateModeEnum): boolean {
        return state == StateModeEnum.insert;
    }
    public static isEditMode(state: StateModeEnum): boolean {
        return state == StateModeEnum.edit;
    }

    public static isBrowseMode(state: StateModeEnum): boolean {
        return state == StateModeEnum.browse;
    }
    public static isModified(state: StateModeEnum): boolean {
        return state == StateModeEnum.modified;
    }
    public static isSelectedMode(state: StateModeEnum): boolean {
        return state == StateModeEnum.selected;
    }
    public static isBrowseSelectedMode(state: StateModeEnum): boolean {
        return state == StateModeEnum.browse || state == StateModeEnum.selected;
    }
    public static isUndefinedMode(state: StateModeEnum): boolean {
        return state == StateModeEnum.undefined;
    }

    public static createGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c === 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }

    public static saveDatagridState(gridName: string, state: any) {
        if (!gridName || !state) {
            return;
        }

        const sessionInfo = { sessionGuid: this.sessionGuid$.value };
        const stateSession = { ...state, ...sessionInfo };

        sessionStorage.setItem(gridName, JSON.stringify(stateSession));
        localStorage.setItem(gridName, JSON.stringify(state));
    }
    public static loadDatagridState(gridName: string) {
        if (!gridName) {
            return;
        }

        const stateSession = sessionStorage.getItem(gridName);
        if (stateSession) {
            let _state = JSON.parse(stateSession);

            if (
                _state.sessionGuid &&
                _state.sessionGuid != this.sessionGuid$.value
            ) {
                for (var i = 0; i < _state.columns.length; i++) {
                    _state.columns[i].filterValue = null;
                    _state.columns[i].filterValues = null;
                }
            }

            return _state;
        }

        const stateLocal = localStorage.getItem(gridName);
        if (stateLocal) {
            let _state = JSON.parse(stateLocal);

            _state.pageIndex = 0;
            for (var i = 0; i < _state.columns.length; i++) {
                _state.columns[i].filterValue = null;
                _state.columns[i].filterValues = null;
            }
            return _state;
        }
        return null;
    }

    public static existsGroupInDatagridState(gridName: string): boolean {
        var _state = this.loadDatagridState(gridName);
        if (_state && _state.columns) {
            return _state.columns.some((e: any) => e.groupIndex >= 0);
        }
        return false;
    }

    public static copyToClipboard(v: string): void {
        try {
            let nav: any;
            nav = window.navigator;

            nav.clipboard.writeText(v).then(
                function () {
                    /* clipboard successfully set */
                },
                function () {
                    /* clipboard write failed */
                    console.log('Error clipboard...');
                }
            );
        } catch (err) {
            console.log('Error copy to clipboard', err.message);
        }
    }
}
