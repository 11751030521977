import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import {
    AngularFirestore,
    AngularFirestoreDocument,
} from 'angularfire2/firestore';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

interface User {
    uid: string;
    email: string;
    photoURL?: string;
    displayName?: string;

    fcmTokens?: { [token: string]: true };
}

@Injectable()
export class FireAuthService {
    user$: Observable<User>;

    constructor(
        private afAuth: AngularFireAuth,
        private afs: AngularFirestore
    ) {
        //// Get auth data, then get firestore user document || null
        this.user$ = this.afAuth.authState.pipe(
            switchMap((user) => {
                if (user) {
                    return this.afs
                        .doc<User>(`users/${user.uid}`)
                        .valueChanges();
                } else {
                    return of(null);
                }
            })
        );
    }

    // googleLogin() {
    //     const provider = new firebase.auth.GoogleAuthProvider();
    //     return this.oAuthLogin(provider);
    // }

    private oAuthLogin(provider): any {
        return this.afAuth.auth.signInWithPopup(provider).then((credential) => {
            this.updateUserData(credential.user);
            return true;
        });
    }

    private updateUserData(user) {
        if (!user || !user.uid) {
            return;
        }

        const userRef: AngularFirestoreDocument<User> = this.afs.doc(
            `users/${user.uid}`
        );

        const data: User = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName ? user.displayName : null,
            photoURL: user.photoURL ? user.photoURL : null,
        };

        return userRef.set(data);
    }

    createUser(email, password): Promise<any> {
        return this.afAuth.auth
            .createUserWithEmailAndPassword(email, password)
            .then((credential) => {
                this.updateUserData(credential.user);
                return true;
            })
            .catch((err) => {
                if (err && err.code) {
                    if (err.code == 'auth/email-already-in-use') {
                        return Promise.reject({
                            errorMessage:
                                'Identificamos que seu e-mail já foi cadastrado, clique na opção "Voltar para a tela de entrada" para acessar',
                        });
                    }
                }
                return Promise.reject(err);
            });
    }

    signIn(email: string, pwd: string): Promise<any> {
        return this.afAuth.auth
            .signInWithEmailAndPassword(email, pwd)
            .then((credential) => {
                this.updateUserData(credential.user);
                return true;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    signOut(): Promise<any> {
        return this.afAuth.auth.signOut();
    }

    resetPassword(email: string): Promise<any> {
        return this.afAuth.auth.sendPasswordResetEmail(email);
    }

    confirmResetPassword(code, password): Promise<any> {
        return this.afAuth.auth.confirmPasswordReset(code, password);
    }
}
