import { AuthUser } from '../models/auth-user.model';

export const AUTH_STORE = 'AUTH_STORE';

export interface AuthState {
    user: AuthUser;
    error: any;
    isProcessing: boolean;
    message: any;
    authenticated: boolean;
    cnpjCpfFornecedor: string;
}

export const authInitialState: AuthState = {
    user: undefined,
    error: undefined,
    isProcessing: undefined,
    message: undefined,
    authenticated: false,
    cnpjCpfFornecedor: undefined,
};
