import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/guards/auth.guard';

const ROUTES: Routes = [
    {
        path: 'login',
        loadChildren: 'app/core/login/login.module#LoginModule',
    },
    {
        path: 'signup',
        loadChildren: 'app/core/signup/signup.module#SignupModule',
    },
    {
        path: 'reset-password',
        loadChildren:
            'app/core/reset-password/reset-password.module#ResetPasswordModule',
    },
    {
        path: 'new-password',
        loadChildren:
            'app/core/new-password/new-password.module#NewPasswordModule',
    },
    {
        path: 'fornecedor',
        loadChildren: 'app/fornecedor/fornecedor.module#FornecedorModule',
    },
    {
        path: '',
        canLoad: [AuthGuard],
        loadChildren: 'app/core/main/main.module#MainModule',
    },
    {
        path: '**',
        redirectTo: '/login',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(ROUTES)],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
