import { ApplicationRef, Injectable, ViewContainerRef } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import { ConfirmService } from '../../shared-services/confirm/confirm.service';

import { MessageService } from '../../shared-services/message/message.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class CnpjObrigatorioGuard implements CanActivate, CanLoad {
    _viewContainerRef: ViewContainerRef;

    constructor(
        private _router: Router,
        private _message: MessageService,
        private _confirm: ConfirmService,
        private _appRef: ApplicationRef,
        private _autenticacaoService: AuthService
    ) {
        this._viewContainerRef =
            this._appRef.components[0].instance.viewContainerRef;
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> | boolean {
        return this._verificarCNPJ();
    }

    canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
        return this._verificarCNPJ();
    }

    private _verificarCNPJ(): any {
        // console.log("******************* CNPJ GUARD *******************")

        this._autenticacaoService.recuperarLogin(false);

        // VERIFICAR SE O CNPJ ESTA PREENCHIDO, EXIBIR AVISO SE ESTIVER INCOMPLETO
        return this._autenticacaoService.cnpjcpf$.pipe(
            first(),
            map((r) => {
                // console.log("CNPJ = ", r)
                if (!r) {
                    // this._message.simpleMessage(
                    //     "Dados Cadastrais Incompletos. Por favor, é necessário completar essas informações no menu cadastro para ter acesso."
                    //     , 5000
                    // );
                    this._confirm
                        .message(
                            `Dados Cadastrais Incompletos`,
                            `É necessário completar seu cadastro para ter acesso as oportunidades públicas, é bem rápido.
                            <br><br>
                            Deseja fazer isso agora?`,
                            this._viewContainerRef
                        )
                        .subscribe((b: boolean) => {
                            if (b) {
                                this._router.navigate(['/cadastro']);
                            }
                        });

                    return false;
                } else {
                    return true;
                }
            })
        );
    }
}
