export class AuthUser {
    codigoDaEmpresa: number;
    codigoDoUsuario: string;
    nome: string;
    email: string;
    administrador: boolean = false;
    loginInterno: boolean = false;
    nomeDoColaborador: string;
    autenticado: boolean = false;
    imagem: string;

    numeroDoFornecedor: number;
    nomeFornecedor: string;
    natureza: string;
    cnpjcpf: string;
}
