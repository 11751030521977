import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthApiService } from './auth-api.service';
import { AuthEffects } from './auth.effects';
import { authReducer } from './auth.reducer';
import { AUTH_STORE } from './auth.state';
import { AuthStoreService } from './auth.store.service';

@NgModule({
    imports: [
        StoreModule.forFeature(AUTH_STORE, authReducer),
        EffectsModule.forFeature([AuthEffects]),
    ],
    exports: [StoreModule, EffectsModule],
    providers: [AuthApiService, AuthStoreService],
})
export class AuthStoreModule {}
