import { NgRxAction } from '../../store/ngrx-action';

export enum AuthActions {
    EFETUAR_LOGIN = '[Auth] Efetuar Login',
    EFETUAR_LOGIN_GOOGLE = '[Auth] Efetuar Login Google',
    EFETUAR_LOGIN_INTERNO = '[Auth] Efetuar Login Interno',

    LOGIN_EFETUADO_COM_SUCESSO = '[Auth] Login efetuado com sucesso',
    LOGIN_NAO_EFETUADO = '[Auth] Login não efetuado',

    RECUPERAR_LOGIN = '[Auth] Recuperar Login',
    LOGIN_RECUPERADO_COM_SUCESSO = '[Auth] Login recuperado com sucesso',

    AUTENTICAR = '[Auth] Autenticar',
    AUTENTICADO_COM_SUCESSO = '[Auth] Autenticado com sucesso',

    DADOS_FORNECEDOR_CARREGAR = '[Auth] Dados do Fornecedor Carregar',
    DADOS_FORNECEDOR_CARREGAR_COM_SUCESSO = '[Auth] Dados do Fornecedor Carregar com sucesso',

    CRIAR_ACESSO = '[Auth] Criar acesso no sistema',
    CRIAR_ACESSO_COM_SUCESSO = '[Auth] Acesso criado com sucesso',

    TROCAR_EMAIL = '[Auth] Trocar E-mail',

    EFETUAR_LOGOUT = '[Auth] Efetuar Logout',
    LOGOUT_EFETUADO = '[Auth] Logout efetuado',

    REGISTRAR_LOGIN = '[Auth] Registrar Login',
    LOGIN_REGISTRADO_COM_SUCESSO = '[Auth] Login registrado com sucesso',
    LOGIN_NAO_REGISTRADO = '[Auth] Login não registrado',

    REGISTRAR_LOGOUT = '[Auth] Registrar Logout',
    LOGOUT_REGISTRADO_COM_SUCESSO = '[Auth] Logout registrado com sucesso',
    LOGOUT_NAO_REGISTRADO = '[Auth] Logout não registrado',

    RESETAR_SENHA = '[Auth] Resetar Senha',
    SENHA_RESETADA_COM_SUCESSO = '[Auth] Senha resetada com sucesso',

    REDEFINIR_SENHA = '[Auth] Redefinir Senha',
    SENHA_REDEFINIDA_COM_SUCESSO = '[Auth] Senha alterada com sucesso',

    LIMPAR = '[Auth] Limpar',
    ERRO = '[Auth] Erro',
}

export class EfetuarLoginAction extends NgRxAction<any> {
    type = AuthActions.EFETUAR_LOGIN;
}
export class EfetuarLoginGoogleAction extends NgRxAction<any> {
    type = AuthActions.EFETUAR_LOGIN_GOOGLE;
}
export class EfetuarLoginInternoAction extends NgRxAction<any> {
    type = AuthActions.EFETUAR_LOGIN_INTERNO;
}

export class LoginEfetuadoComSucessoAction extends NgRxAction<any> {
    type = AuthActions.LOGIN_EFETUADO_COM_SUCESSO;
}
export class LoginNaoEfetuadoAction extends NgRxAction<any> {
    type = AuthActions.LOGIN_NAO_EFETUADO;
}

export class RecuperarLoginAction extends NgRxAction<any> {
    type = AuthActions.RECUPERAR_LOGIN;
}
export class LoginRecuperadoComSucessoAction extends NgRxAction<any> {
    type = AuthActions.LOGIN_RECUPERADO_COM_SUCESSO;
}

export class AutenticarAction extends NgRxAction<any> {
    type = AuthActions.AUTENTICAR;
}
export class AutenticadoComSucessoAction extends NgRxAction<any> {
    type = AuthActions.AUTENTICADO_COM_SUCESSO;
}

export class DadosFornecedorCarregarAction extends NgRxAction<any> {
    type = AuthActions.DADOS_FORNECEDOR_CARREGAR;
}
export class DadosFornecedorCarregarComSucessoAction extends NgRxAction<any> {
    type = AuthActions.DADOS_FORNECEDOR_CARREGAR_COM_SUCESSO;
}

export class CriarAcessoAction extends NgRxAction<any> {
    type = AuthActions.CRIAR_ACESSO;
}
export class CriarAcessoComSucessoAction extends NgRxAction<any> {
    type = AuthActions.CRIAR_ACESSO_COM_SUCESSO;
}

export class TrocarEmailAction extends NgRxAction<any> {
    type = AuthActions.TROCAR_EMAIL;
}

export class EfetuarLogoutAction extends NgRxAction<any> {
    type = AuthActions.EFETUAR_LOGOUT;
}
export class LogoutEfetuadoAction extends NgRxAction<any> {
    type = AuthActions.LOGOUT_EFETUADO;
}

export class RegistrarLoginAction extends NgRxAction<any> {
    type = AuthActions.REGISTRAR_LOGIN;
}
export class LoginRegistradoComSucessoAction extends NgRxAction<any> {
    type = AuthActions.LOGIN_REGISTRADO_COM_SUCESSO;
}
export class LoginNaoRegistradoComSucessoAction extends NgRxAction<any> {
    type = AuthActions.LOGIN_NAO_REGISTRADO;
}

export class RegistrarLogoutAction extends NgRxAction<any> {
    type = AuthActions.REGISTRAR_LOGOUT;
}
export class LogoutRegistradoComSucessoAction extends NgRxAction<any> {
    type = AuthActions.LOGOUT_REGISTRADO_COM_SUCESSO;
}
export class LogoutNaoRegistradoComSucessoAction extends NgRxAction<any> {
    type = AuthActions.LOGOUT_NAO_REGISTRADO;
}

export class ResetarSenhaAction extends NgRxAction<any> {
    type = AuthActions.RESETAR_SENHA;
}
export class SenhaResetadaAction extends NgRxAction<any> {
    type = AuthActions.SENHA_RESETADA_COM_SUCESSO;
}

export class RedefinirSenhaAction extends NgRxAction<any> {
    type = AuthActions.REDEFINIR_SENHA;
}
export class SenhaRedefinidaAction extends NgRxAction<any> {
    type = AuthActions.SENHA_REDEFINIDA_COM_SUCESSO;
}

export class LimparAction extends NgRxAction<any> {
    type = AuthActions.LIMPAR;
}
export class ErroAction extends NgRxAction<any> {
    type = AuthActions.ERRO;
}

export type AuthAction =
    | EfetuarLoginAction
    | EfetuarLoginGoogleAction
    | EfetuarLoginInternoAction
    | LoginEfetuadoComSucessoAction
    | LoginNaoEfetuadoAction
    | RecuperarLoginAction
    | LoginRecuperadoComSucessoAction
    | AutenticarAction
    | AutenticadoComSucessoAction
    | DadosFornecedorCarregarAction
    | DadosFornecedorCarregarComSucessoAction
    | RegistrarLoginAction
    | LoginRegistradoComSucessoAction
    | LoginNaoRegistradoComSucessoAction
    | CriarAcessoAction
    | CriarAcessoComSucessoAction
    | TrocarEmailAction
    | EfetuarLogoutAction
    | LogoutEfetuadoAction
    | ResetarSenhaAction
    | SenhaResetadaAction
    | RedefinirSenhaAction
    | SenhaRedefinidaAction
    | LimparAction
    | ErroAction;
