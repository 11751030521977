import { NgModule } from '@angular/core';
import { AngularFireModule, FirebaseOptionsToken } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFirestoreModule } from 'angularfire2/firestore';

import { environment } from '../../environments/environment';
import { FireAuthService } from './services/fire-auth.service';
import { FireMessagingService } from './services/fire-messaging.service';

@NgModule({
    imports: [AngularFireModule, AngularFirestoreModule, AngularFireAuthModule],
    declarations: [],
    providers: [
        { provide: FirebaseOptionsToken, useValue: environment.firebaseConfig },
        FireAuthService,
        FireMessagingService,
    ],
})
export class AppFirebaseModule {}
